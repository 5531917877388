.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.projects__items {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid rgb(56, 56, 56);
    transition: var(--transition);
}

.projects__items:hover {
    background: transparent;
}

.projects__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.projects__items h3 {
    margin: 1.2rem 0 0.2rem;
}

.projects__items h5 {
    color: gray;
    margin: 0 0 1.2rem;
}

.placeholder {
    opacity: 0;
}

@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}